@import '../../../../styles/basics';

.search-container {
  max-width: 800px;
  margin: auto;
  padding: 16px 5px 40px;

  // The following code needs to be able to focus top menu item during tabbing by keyboard.
  // https://css-tricks.com/a-css-approach-to-trap-focus-inside-of-an-element/#aa-the-css-trick-to-focus-trapping
  &:not(:focus-within) {
    background-color: rgb(255, 255, 254);
    transition: background-color 0.01ms;
  }

  .search-input-label {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin-top: 30px;
    margin-bottom: 5px;
    color: $black;
    @include media-breakpoint-up(lg){
      margin-top: 60px;
    }
  }
}

.desktop {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
